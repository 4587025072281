import domReady from '@roots/sage/client/dom-ready';
import videojs from 'video.js';
import axios from 'axios';

window.axios = axios;
/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  const { endpoint } = video_progress_settings;
  const el = document.querySelector('#video-player');

  const setupVideoPlayer = (el, endpoint) => {
    const nonce = el.getAttribute('data-nonce');
    const video_id = el.getAttribute('data-video-id');
    const video_progress = parseInt(el.getAttribute('data-video-progress'), 10);
    let firstPlay = true;

    let updateInterval = null;
    const UPDATE_INTERVAL = 3000;

    const saveProgress = async (progress) => {
      var params = new URLSearchParams();
      params.append('action', 'video_progress');
      params.append('video_id', parseInt(video_id, 10));
      params.append('nonce', nonce);
      params.append('progress', progress);

      try {
        const response = await axios.post(endpoint, params);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const player = videojs(el);
    player.on('play', async () => {
      if (firstPlay && video_progress) {
        firstPlay = false;
        player.currentTime(video_progress);
      }

      updateInterval = setInterval(async () => {
        const progress = Math.round(player.currentTime());
        await saveProgress(progress);
      }, UPDATE_INTERVAL);
    });

    player.on('pause', async () => {
      clearInterval(updateInterval);
      const progress = Math.round(player.currentTime());
      await saveProgress(progress);
    });

    player.on('stop', async () => {
      clearInterval(updateInterval);
      const progress = Math.round(player.currentTime());
      await saveProgress(progress);
    });
  };

  if (el) {
    setupVideoPlayer(el, endpoint);
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
